<template>
  <sign-page
    title-text="职工信息"
    :request="request"
    table-size="large"
    :column-list="columnList"
    allAuthFuns
    :form-parms-add="formParms"
    :form-parms-update="formParms"
    :table-actions-fixed="true"
    :tableActionsWidth="100"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import { request } from '@/api'

export default {
  computed: {
    formParms: {
      get () {
        let data = [
          {
            type: 'input',
            label: '编码',
            key: 'code',
            check: {
              required: true
            }
          },
          {
            type: 'input',
            label: '姓名',
            key: 'name',
            check: {
              required: true
            }
          },
          {
            type: 'input',
            label: '身份证号',
            key: 'idNo',
            check: {
              required: true
            }
          },
          {
            type: 'datePicker',
            label: '开始时间',
            key: 'startTime',
            check: {
              required: true
            }
          },
          {
            type: 'datePicker',
            label: '结束时间',
            key: 'endTime'
          }
        ]
        return data
      }
    },
    columnList: {
      get () {
        let data = [
          {
            title: '编码',
            sort: true,
            field: 'code'
          },
          {
            title: '姓名',
            sort: true,
            field: 'name'
          },
          {
            title: '身份证号',
            sort: true,
            field: 'idNo'
          },
          {
            title: '开始时间',
            sort: true,
            field: 'startTime',
            dataType: Date,
            render: (h, rowData) => {
              return h('div', rowData && rowData.startTime ? rowData.startTime.slice(0, 10) : '-')
            }
          },
          {
            title: '结束时间',
            sort: true,
            field: 'endTime',
            dataType: Date,
            render: (h, rowData) => {
              return h('div', rowData && rowData.endTime ? rowData.endTime.slice(0, 10) : '-')
            }
          }
        ]
        return data
      }
    }
  },
  data () {
    return {
      request: {
        get: function (parm) {
          return request('/performance/wage_worker', 'get', parm)
        },
        add: function (parm) {
          return request('/performance/wage_worker', 'post', parm)
        },
        update: function (id, parm) {
          return request('/performance/wage_worker/' + id, 'put', parm)
        },
        del: function (id) {
          return request('/performance/wage_worker/' + id, 'delete')
        }
      }
    }
  }
}
</script>
